<template>
    <div class="release-list release">
        <div class="m-0 p-0">
            <b-card class="my-2"> 
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="note-header mb-0">Version 1.5.1</h3>
                    <span class="date">July 31 2024</span>
                </div>
                <p class="my-2">
                    We're thrilled to announce Version 2.0 of our product! This update features faster performance, a modernized user interface, and new features to enhance your experience. Explore the new version and share your feedback. Thank you for your continued support
                </p>
                <b-button variant="primary" to="/release-detail" pill >Full Details</b-button>
            </b-card>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
    BBadge,
    BProgress,
    BProgressBar,
    BSidebar,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      links: [],
      new_link: {
        name: "",
        description: "",
        link: "",
      },

      selected_link: {
        hashid: "",
        name: "",
        description: "",
        link: "",
      },
      userData: this.$cookies.get("userData"),
      release_notes:[],
    };
  },
  created(){
    this.release_notes = whats_new;
  }
};
</script>